<template>   
    <div class="card-body min-h-min" :class="body_class">
            <slot ></slot>
        </div>
  </template>
  
  <script>
  export default {
    name:"CardBody",
    props:{
        body_class:{
            type: String,
            default:''
        },
    },
    data() {
      return {
      }
    },
    methods: {
    }
  }
  </script>