<template>
    <div :class="dropdown_class">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "CardBody",
    props: {
        dropdown_class: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>