
<template>
    <ElementLayout>
        <template #element>
            <div class="w-full">
                <VueFileAgent class=" mt-2" ref="vueFileAgent" :multiple="multiple" :linkable="true" :deletable="true"
                    :meta="true" :resumable="true" v-model="model" :accept="accept">
                    <template v-slot:before-inner>
                        <div class="flex-row row">
                            <div class="w-full p-4">
                                <p class="my-2 float-left w-auto font-normal text-base text-blue-950 dark:text-white">File(s)</p>

                                <button class="my-2 py-2 px-4 bg-yellow-protostars-light dark:bg-yellow-protostars float-right w-auto rounded-lg">
                                    Choose files
                                </button>
                            </div>
                            <div class="w-1/2"></div>
                        </div>
                    </template>
                    <template v-slot:file-preview-new>
                        <div key="new"></div>
                    </template>
                </VueFileAgent>

            </div>
        </template>

        <!-- Default element slots -->
        <template v-for="(component, slot) in elementSlots" #[slot]>
            <slot :name="slot" :el$="el$">
                <component :is="component" :el$="el$" />
            </slot>
        </template>
    </ElementLayout>
</template>

<script>
import { ref } from 'vue'
import { defineElement } from '@vueform/vueform'

export default defineElement({
    name: 'FileInputElement',
    props: {
        accept: {
            type: String,
            required: false,
            default: '',
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { type } = ref(props)

        console.log(type) // 'custom' - the element type

        console.log(Object.keys(props))
    },

    data() {
        return {


        }
    }
})
</script>

<style lang="scss"></style>