<template>
    <Vueform ref="searchFilterRef" class="w-full" add-class="vueFormContainer vueFormFilter" :float-placeholders="false" v-model="searchData">
        <TextElement name="search" inputType="search" placeholder="Search" @change="changeVal">

            <template #addon-before>
                <font-awesome-icon class="addon" icon='fa-solid fa-magnifying-glass' />
            </template>
        </TextElement>
    </Vueform>
</template>

<script>
import { useDebounceFn } from '@vueuse/core'

export default {
    name: "SearchFilterInput",
    props: {
    },

    emits: ['updateSearchValue'],
    data() {
        let temp = this
        return {
            searchData: null
        }
    },
    methods: {
        changeVal(newval, oldval) {
            // console.log("change val", newval)
            this.debouncedFn(this,newval)
            // this.$emit("updateSearchValue", newval)
            console.log(this.$refs.searchFilterRef)
        },
        debouncedFn: useDebounceFn((component,newval) => {
            // do something
            // console.log("change debounce",component.searchData.search)
            component.$emit("updateSearchValue", newval)
        }, 1000)
    }
}
</script>