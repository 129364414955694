<template>
    <div class=" py-1 mb-4">
        <nav class="breadcrumb-container" aria-label="Breadcrumb">
            <ol class="">
                <li v-for="(item, index) in items" :key="index">
                    <div class="">
                        <span v-if="index != 0" class="divider">/</span>
                        <a v-if="item.to" :href="item.to" class="">
                            <font-awesome-icon :icon="item.icon" />
                            {{ item.text }}
                        </a>
                        <span v-else class="active">
                            {{ item.text }}
                        </span>
                    </div>
                </li>
            </ol>
        </nav>
    </div>
</template>


<script>

export default {
    name: "BreadcrumbIndex",
    components: {},
    props: [
        "items"
    ],
    data() {
        let temp = this;
        return {
        }
    },
    mounted() {
    },
}
</script>