<template>
    <div class="card-header " :class="header_class">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "CardHeader",
    props: {
        header_class: {
            type: String,
            default: 'border-none'
        },
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>