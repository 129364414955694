<template>
    <ElementLayout>
        <template #element>
            <div class="w-full h-auto min-h-[250px]">
                <quill-editor ref="quillRef" theme="snow" content-type="html" :toolbar="customToolbar" v-model="model"
                    v-model:content="model"  :placeholder="placeholder"
                    @textChange="changeText"></quill-editor>
            </div>
        </template>

        <!-- Default element slots -->
        <template v-for="(component, slot) in elementSlots" #[slot]>
            <slot :name="slot" :el$="el$">
                <component :is="component" :el$="el$" />
            </slot>
        </template>
    </ElementLayout>
</template>

<script>
import { ref, toRefs, watch } from 'vue'
import { defineElement } from '@vueform/vueform'
import { Quill } from '@vueup/vue-quill';

// Generate code-friendly font names
function getFontName(font) {
    return font.toLowerCase().replace(/\s/g, "-");
}

// Specify Quill fonts
const fontList = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana'];
const fontNames = fontList.map(font => getFontName(font));
const fonts = Quill.import('formats/font');
fonts.whitelist = fontNames;
Quill.register(fonts, true);

// Add fonts to CSS style
let fontStyles = "";
fontList.forEach(function (font) {
    let fontName = getFontName(font);
    fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
        "content: '" + font + "';" +
        "font-family: '" + font + "', sans-serif;" +
        "}" +
        ".ql-font-" + fontName + "{" +
        " font-family: '" + font + "', sans-serif;" +
        "}";
});
// console.log(fontStyles)

const node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);


export default defineElement({
    name: 'QuillEditorElement',
    setup(props, context) {
        const { type } = toRefs(props)

        console.log(type.value) // 'custom' - the element type

        console.log(Object.keys(props))
        console.log((props))
        console.log(context)

        const quillRef = ref(null)
        console.log(quillRef)
        const is_disabled = ref(props.disabled)
        const init = () => {
            console.log("iiiii");
            console.log(type.value)
            console.log(props)
            console.log(props.value, '----', props.disabled)
            quillRef.value.getQuill().enable(!props.disabled)
        }

        // watch works directly on a ref
        watch(is_disabled, async (newval, oldval) => {
            console.log("watch is disable")
        })
        // watch(model, async (newval, oldval) => {
        //     console.log("watch model")
        //     console.log(newval)
        //     console.log(oldval)
        // })
        function changeText(delta, oldContents, source) {
            console.log("change text")
            console.log(context)
            console.log(delta)
            console.log(oldContents)
            console.log(source)
        }

        return { props, quillRef, init, is_disabled, changeText };
    },

    data() {
        return {

            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ["link", "image", "video"],
                [{ 'font': fonts.whitelist }],
                ["clean"], // remove formatting button
            ],
        }
    },
    methods: {

    }
})
</script>

<style lang="scss">
.form-text-input {
    width: 100%;

}

.ql-container {
    height: 200px !important;
    max-height: 200px !important;
}

.ql-editor {
    max-height: 100%;
    overflow: auto;
}
</style>