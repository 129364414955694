import { createApp } from 'vue'
// import Vue, { createApp } from '@vue/compat';

import App from './App.vue'
import router from './router'


import VueAxios from 'vue-axios'
import axios from 'axios'

// import { BootstrapVue } from 'bootstrap-vue'

// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)



// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'


// import "preline/preline";


import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
// import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faArrowLeft, faArrowLeftLong,faArrowRightLong, faBars, faCircle, faCircleArrowRight, faCircleCheck, faCircleInfo, faCirclePlus, faCircleXmark, faHouse, faMagnifyingGlass, faMicrophone, faMoon, faPause, faSquareCheck, faSun } from '@fortawesome/free-solid-svg-icons'
// import { faCopyright } from '@fortawesome/free-regular-svg-icons'
// import { faGithub } from '@fortawesome/free-brands-svg-icons'
// library.add({ faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faArrowLeft, faArrowLeftLong,faArrowRightLong, faBars, faCircle, faCircleArrowRight, faCircleCheck, faCircleInfo, faCirclePlus, faCircleXmark, faCopyright, faGithub, faHouse, faMagnifyingGlass, faMicrophone, faMoon, faPause, faSquareCheck, faSun });

/* import specific icons */
import { fas, faPlus,faPaperclip  } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(fas)
library.add(faPlus)
library.add(faPaperclip)



import Vueform from '@vueform/vueform'
import vueformConfig from '../vueform.config'
import Builder from '@vueform/builder'
import builderConfig from '../builder.config'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
// import '@vueup/vue-quill/dist/vue-quill.bubble.css';

import VueFileAgentNext from '@boindil/vue-file-agent-next'
import '@boindil/vue-file-agent-next/dist/vue-file-agent-next.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// import '@sweetalert2/theme-dark/dark.scss';
const sweet_alert_options = {
  focusConfirm: false,
  customClass: {
    htmlContainer: "!text-2xl !leading-normal",
    confirmButton: "!bg-grey-protostars",
  },
}

import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

// import '../node_modules/flowbite-vue/dist/index.css'

const app = createApp(App)

app.component("font-awesome-icon", FontAwesomeIcon)

app.use(router)


axios.defaults.withCredentials = false
// axios.defaults.baseURL = 'https://microservices.protostars.ai/api/'
axios.defaults.baseURL = 'https://microservices-staging.protostars.ai/api/'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
app.use(VueAxios, axios)

app.use(Vueform, vueformConfig)
app.use(Builder, builderConfig)

app.component('QuillEditor', QuillEditor);

app.use(VueTelInput, {
  mode: 'international',
  enabledCountryCode: true,
  defaultCountry: 'IE',
  preferredCountries: ['IE', 'GB', 'FR'],
  autoFormat: true,
  dropdownOptions: { showSearchBox: true },
  inputOptions: { placeholder: "Phone number", showDialCode: true }

});

app.use(VueFileAgentNext);
app.use(VueSweetalert2, sweet_alert_options)
// import AudioRecorder from 'vue-audio-recorder'
// app.use(AudioRecorder)
// import { VueVoiceRecording } from 'vue-voice-recording';
// import 'vue-voice-recording/dist/style.css'; // import it if you want to use the default template.

// import { AVPlugin } from "vue-audio-visual";
// app.use(AVPlugin);

// app.use(VueVoiceRecording)
/**{
    mode: "international",
    enabledCountryCode: true,
    enabledFlags: true,
    dynamicPlaceholder:true,
    // defaultCountry: 'IE',
    // preferredCountries: ['IE', 'GB','FR'],
    autoFormat: true,
    dropdownOptions: { showSearchBox: true },
    inputOptions: { placeholder: "Phone number", showDialCode: true }
  } */

app.use(VueAwesomePaginate)

import BreadcrumbIndex from '@/components/partials/BreadcrumbIndex.vue'
import CardIndex from '@/components/partials/card_components/CardIndex.vue'
import CardHeader from '@/components/partials/card_components/CardHeader.vue'
import CardBody from '@/components/partials/card_components/CardBody.vue'
import DropdownContainer from '@/components/partials/dropdown_components/DropdownContainer.vue'
import DropdownButton from '@/components/partials/dropdown_components/DropdownButton.vue'
import DropdownItemsUl from '@/components/partials/dropdown_components/DropdownItemsUl.vue'
import DropdownItem from '@/components/partials/dropdown_components/DropdownItem.vue'
import ProgressBar from '@/components/partials/ProgressBar.vue'
import OverlayLoading from '@/components/partials/OverlayLoading.vue'
import SearchFilterInput from '@/components/partials/SearchFilterInput.vue'
import SpinnerLoading from '@/components/partials/SpinnerLoading.vue'
app.component('BreadcrumbComponent', BreadcrumbIndex);
app.component('CardPartial', CardIndex);
app.component('CardHeader', CardHeader);
app.component('CardBody', CardBody);
app.component('DropdownContainer', DropdownContainer);
app.component('DropdownButton', DropdownButton);
app.component('DropdownItemsUl', DropdownItemsUl);
app.component('DropdownItem', DropdownItem);
app.component('ProgressBar', ProgressBar);
app.component('OverlayLoading', OverlayLoading);
app.component('SearchFilterInput', SearchFilterInput);
app.component('SpinnerLoading', SpinnerLoading);


import HighchartsVue from 'highcharts-vue'
app.use(HighchartsVue)

import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
// import 'datatables.net-select';
// import 'datatables.net-responsive';
DataTable.use(DataTablesCore);
app.component("DataTable", DataTable)




import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
app.component('VueDatePicker', VueDatePicker);

// import { quillEditor } from 'vue3-quill'
// app.use(quillEditor)

import './assets/css/Graphik-fonts.css'
import './assets/css/auth-styles.scss'
import './assets/css/buttons-styles.scss'
import './assets/css/styles.scss'
import './assets/css/styles-new.scss'
import './assets/css/styles-protostars.scss'
import './assets/css/form-custom-styles.scss'

import './assets/css/styles-protostars-new.scss'

app.mount('#app')
