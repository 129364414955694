<template>
    <nav class="bg-white h-20 dark:bg-black-protostars fixed w-full z-20 top-0 border-0 dark:border-gray-600">
        <!-- <nav class="bg-white border-gray-200 dark:bg-gray-900"> -->
        <div class="max-w-screen-xl min-w-full h-full flex flex-wrap items-center justify-between mx-auto p-4">
            <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
                <img v-if="isDark" src="@/assets/images/protostars/logo-light.png" class="h-8" alt="Protostars Logo" />
                <img v-else src="@/assets/images/protostars/logo.png" class="h-8" alt="Protostars Logo" />
            </a>
            <div class="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                <button class="text-gray-500 dark:text-gray-200 mx-2 " @click="clickToggleDark()">
                    <span v-if="!isDark"><font-awesome-icon icon="fa-solid fa-moon" /></span>
                    <span v-else><font-awesome-icon icon="fa-solid fa-sun" /></span>
                </button>


            </div>
        </div>
    </nav>
</template>

<script>

export default {
    name: 'NavbarDefault',
    components: {

    },
    props: ['isDark'],
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        clickToggleDark() {
            console.log(this.isDark)
            this.$emit('updateIsDark',)
        }
    }
}
</script>

<style></style>
