<template>
  <div class="flex justify-between mb-1">
    <span class="text-sm/normal font-medium text-grey-protostars-1000 dark:text-white">{{label}}</span>
    <span class="text-xs/normal font-medium text-primary dark:text-primary bg-primary-50 dark:bg-primary-50 py-0.5 px-2 rounded-full">{{ value_text }}</span>
  </div>
  <div class="w-full bg-blue-25 rounded-full h-2.5 dark:bg-blue-25">
    <div class="bg-primary dark:bg-primary h-2.5 rounded-full" :style="'width: ' + value + '%'"></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    label: {
      required: false,
      type: String
    },
    value: {
      required: true,
      type: Number
    },
    value_text: {
      required: false,
      type: String
    }
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>