<template>
    <div :id="id"
        class="z-20 hidden rounded-lg shadow-2xl dark:shadow-2xl  bg-white dark:bg-grey-protostars-900 divide-y divide-gray-100   ">
        <ul class="py-2 text-sm/normal text-grey-protostars-500r dark:text-grey-protostars-100" :aria-labelledby="button_id">
            <slot></slot>
        </ul>
    </div>
</template>

<script>
export default {
    name: "DropdownItemsUl",
    props: {
        id:{
            type: String,
            required: true
        },
        button_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>