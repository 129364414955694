<template>
    <div class="card p-0" :class="card_class">
      <slot></slot>
        <!-- <div class="card-header " :class="header_class">
            <slot  name="cardHeader"></slot>
        </div>
        <div class="card-body min-h-min" :class="body_class">
            <slot  name="cardContent"></slot>
        </div> -->
    </div>
  </template>
  
  <script>
  export default {
    name:"CardIndex",
    props:{
        card_class:{
            type: String,
            default:'border-none'
        },
        header_class:{
            type: String,
            default:'border-none'
        },
        body_class:{
            type: String,
            default:''
        },
    },
    data() {
      return {
      }
    },
    methods: {
    }
  }
  </script>